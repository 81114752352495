import React from "react";
import Layout from "../components/layout";
import Head from "../components/head";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export const query = graphql`
  query($slug: String!) {
    contentfulPosts(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      body {
        json
      }
    }
  }
`;
const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"];
        const url = node.data.target.fields.file["en-US"].url;
        return (
          <div class="text-center">
            <img alt={alt} src={url} class={`img-fluid img-shadow`} />
          </div>
        );
      }
    }
  };
  return (
    <Layout>
      <Head title={props.data.contentfulPosts.title} />
      <h1>{props.data.contentfulPosts.title}</h1>
      <p>{props.data.contentfulPosts.publishedDate}</p>
      <div>
        {documentToReactComponents(
          props.data.contentfulPosts.body.json,
          options
        )}
      </div>
    </Layout>
  );
};

export default Blog;
